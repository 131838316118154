import Header from './components/Header'
import ContactPresentation from './components/contact/contactPresentation'
import ContactForm from './components/contact/contactForm'
import ContactFindUs from './components/contact/contactFindUs'
import FooterFF from './components/FooterFF'

function Contact() {

  return (
    <>
      <Header/>
      <ContactPresentation/>
      <ContactForm/>
      <ContactFindUs/>
      <FooterFF/>
    </>
  )
}

export default Contact