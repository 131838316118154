import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function CLSlides() {
    const [clSlides, setClSlides] = useState([]);
    const [paragraphe2, setParagraphe2] = useState('');

    useEffect(() => {
        const fetchSlides = async () => {
            try {
                const response = await fetch('/content/cuisineLocative/cuisineLocative.json'); // Adjust the path accordingly
                const data = await response.json();

                const slides = Object.keys(data.slides).map((i) => (
                    <div className="CLSlides" key={i}>
                        <img className="CLSlideImg" src={data.slides[i].path} alt={data.slides[i].alt} loading="lazy" />
                    </div>
                ));

                setClSlides(slides);
                setParagraphe2(data.body.paragraphe_2);
            } catch (error) {
                console.error('Error fetching slides:', error);
            }
        };

        fetchSlides();
    }, []);
    if (!clSlides || !paragraphe2) {
        return <div className="loadingFiller"></div>;
      }

    return (
        <>
            <div>
                <Carousel
                    id="CLSlidesCarousel"
                    showArrows={false}
                    showThumbs={false}
                    autoPlay={true}
                    interval={10000}
                    stopOnHover={false}
                    emulateTouch={true} // Ensure touch support
                    swipeable={true}
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={50}
                    showStatus={false}
                    infiniteLoop={true}
                >
                    {clSlides}
                </Carousel>
            </div>
            <p className="CLDesktop StrucRearr">{paragraphe2}</p>
        </>
    );
}

export default CLSlides;
