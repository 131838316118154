import React, { useState, useEffect } from 'react';
import CLSlides from './CLSlides';

function CLBody() {
  const [clData, setCLData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/content/cuisineLocative/cuisineLocative.json');
        const data = await response.json();
        setCLData(data);
      } catch (error) {
        console.error('Error fetching Cuisine Locative data:', error);
      }
    };

    fetchData();
  }, []);

  if (!clData) {
    return <div className="loadingFiller"></div>;
  }

  const clListItems = Object.keys(clData.body.liste.items).map((i) => (
    <li key={i}>{clData.body.liste.items[i]}</li>
  ));

  return (
    <>
      <div className="clBlackBG">
        <section className="clBodyWrapper">
          <div className="clIncitatif">
            <h2 className="CLDesktop">Cuisine Locative</h2>
            <h3>{clData.body.title}</h3>
            <p className="CLMobile">{clData.body.paragraphe_1}</p>
            <p className="CLMobile">{clData.body.paragraphe_2}</p>
            <a href='/contact?cuisinelocative' className="clIncitBtn" >{clData.body.button}</a>
          </div>
          <div className="CLMobile">
            <CLSlides className='CLMobile' />
          </div>
          <div className="clDescription">
            <div>
              <p className="CLDesktop">{clData.body.description}</p>
              <p></p>
            </div>
            <div className="clUtilities">
              <p>{clData.body.liste.title}</p>
              <ul>
                {clListItems}
              </ul>
            </div>
          </div>
        </section>
        <div className='CLDesktop CLSlidesFit'>
          <CLSlides />
        </div>
        <section className="CLClients">
          <div className="CLTitleWrapper">
            <h4 className="CLTitle">Nos clients</h4>
            <div className='CLliner'></div>
          </div>
        </section>
      </div>
    </>
  );
}

export default CLBody;
