import React, { useEffect } from 'react';
import heroLogo from '../../assets/home/Hero/HeroLogo.svg';


function HomeHero() {
  useEffect(() => {
    const $bubbles = document.querySelector('.bubbles');

    function bubbles() {
      // Clear existing bubbles
      $bubbles.innerHTML = '';

      // Settings
      const min_bubble_count = 15,
        min_bubble_size = 5,
        max_bubble_size = 60;

      // Calculate a random number of bubbles based on our min/max
      const bubbleCount =
        min_bubble_count + Math.floor(Math.random());

      // Create the bubbles
      for (let i = 0; i < bubbleCount; i++) {
        $bubbles.innerHTML += `
          <div class="bubble-container">
            <div class="bubble"></div>
          </div>`;
      }

      // Now randomize the various bubble elements
      $bubbles.querySelectorAll('.bubble-container').forEach((bubbleContainer) => {
        const pos_rand = Math.floor(Math.random() * 100);
        const size_rand =
          min_bubble_size + Math.floor(Math.random() * (max_bubble_size + 1));
        const delay_rand = Math.floor(Math.random() * 10);
        const speed_rand = 3 + Math.floor(Math.random() * 10);
        const blur_rand = Math.floor(Math.random() * 1.2);

        bubbleContainer.style.left = pos_rand + '%';
        bubbleContainer.style.animationDuration = speed_rand + 's';
        bubbleContainer.style.animationDelay = delay_rand + 's';
        bubbleContainer.style.filter = `blur(${blur_rand}px)`;

        const bubble = bubbleContainer.querySelector('.bubble');
        bubble.style.width = size_rand + 'px';
        bubble.style.height = size_rand + 'px';
      });
    }

    // Initial call to bubbles function
    bubbles();
  }, []); // Empty dependency array ensures the useEffect runs once on mount

  return (
    <>
      <div id="homeHeroWrapper">
        <img id='homeHeroLogo' src={heroLogo} alt="logo Fervent Ferment" />
        <div className='bubbles'></div>
      </div>
    </>
  );
}

export default HomeHero;
