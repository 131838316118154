import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';

function HomeSlide() {
    const [homeSlidesData, setHomeSlidesData] = useState([]);

    useEffect(() => {
        const fetchHomeSlidesData = async () => {
            try {
                const response = await fetch('/content/home/home.json'); // Adjust the path accordingly
                const data = await response.json();
        
                const slides = Object.keys(data.slides)
                    .sort() // Sort the keys to ensure consistent order
                    .map((key) => ({
                        title: data.slides[key].title,
                        text: data.slides[key].text,
                        href: data.slides[key].href,
                        button: data.slides[key].button,
                        imagePath: data.slides[key].image.path,
                        imageAlt: data.slides[key].image.alt,
                    }));
        
                setHomeSlidesData(slides);
            } catch (error) {
                console.error('Error fetching home slides data:', error);
            }
        };

        fetchHomeSlidesData();
    }, []);

    if (!homeSlidesData) {
        return <div className="loadingFiller"></div>;
      }

    const homeSlides = homeSlidesData.map((slide, index) => (
        <div className="homeSlides" key={index}>
            <div className="hslideWrapper">
                <div className="imgGradiant">
                    <img className="homeSlideImg" src={slide.imagePath} alt={slide.imageAlt} />
                </div>
                <div className="slideSecondBox">
                    <h3 className="homeSlideTitle">{slide.title}</h3>
                    <p className="homeSlideText">{slide.text}</p>
                    <a className="homeSlideBtn" href={slide.href}>{slide.button}</a>
                </div>
            </div>
        </div>
    ))

    return (
        <div id="HomeSlidesWrapper">
            <Carousel
                id="homeSlidesCarousel"
                showArrows={false}
                showThumbs={false}
                autoPlay={true}
                interval={7000}
                stopOnHover={false}
                emulateTouch={true} // Ensure touch support
                swipeable={true}
                preventMovementUntilSwipeScrollTolerance={true}
                swipeScrollTolerance={50}
                showStatus={false}
                infiniteLoop={true}
            >   
                {homeSlides[0]}
                {homeSlides[1]}
                {homeSlides[2]}
                {homeSlides[3]}
                {homeSlides[4]}
            </Carousel>
        </div>
    );
}

export default HomeSlide;
