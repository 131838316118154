import React from 'react'
import ReactDOM from 'react-dom/client'
import Routes from './pages/routes'
import './styles/p_Home.css'
import './styles/c_CLBody.css'
import './styles/c_aboutContent.css'
import './styles/c_pagesPresentation.css'
import './styles/c_CLSlides.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './styles/c_HomeClub.css';
import './styles/c_HomeHero.css';
import './styles/fx_bubbles.css';
import './styles/c_HomeSlides.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"
import './styles/c_pagesBoites.css'
import './styles/c_pagesPresentation.css'
import './styles/c_TraiteurSlide.css'
import './styles/c_footerCL.css';
import './styles/c_footerFF.css';
import "./styles/c_Header.css"
import "./styles/c_HeaderCL.css"
import './styles/c_contactForm.css'
import './styles/c_contactFindUs.css'
import "./styles/p_notFound.css"



ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Routes/>
  </React.StrictMode>,
)
