
function NotFound() {


  return (
    <>
      <div id="notFoundWrapper">
          <div id='nf_background'></div>
          <div id='nf_logo'></div>
          <div className="nfContentWrapper">
              <div className="jarWrapper">
                  <div id='nf_jar'></div>
              </div>
              <div className="nfTextWrapper">
                <h1 className="oups">Oups, cette fermentation n&apos;existe pas...</h1>
                <a className="nfBack" href="/">Retour à l'acceuil</a>
              </div>
          </div>
      </div>
    </>
  )
}

export default NotFound