import HeaderCL from "./components/HeaderCL"
import CLBody from "./components/cuisineLocative/CLBody"
import FooterCL from "./components/FooterCL"
import CLSlidesClients from "./components/cuisineLocative/CLSlidesClients"

function CuisineLocative(){
    return(
        <>
        <div className="CLBackground">
            <HeaderCL/>
            <CLBody/>
            <CLSlidesClients/>
            <FooterCL/>
        </div>
        </>
    )
}

export default CuisineLocative