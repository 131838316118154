import { useState } from "react";
import $ from "jquery";

function ContactForm() {
  const urlSelectedParam = window.location.href.split('?')[1];
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    const isMissChecked = form.find("#miss").prop("checked");
    if (isMissChecked) {
      return;
    }

    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success: function (data) {
        console.log(data);
        setIsSubmitted(true);
      },
    });
  };

  return (
    <>
      <div className="contactWrapper">
        <div className="contatFormHeader">
          <p>Vous désirez passer une commande?</p>
          <p>Des questions?</p>
          <p>Des commentaires?</p>
        </div>
        <div className="contactFormWrapper">
          <form
            id="contactForm"
            action="https://ferventferment.ca/server.php"
            method="post"
            onSubmit={(event) => handleSubmit(event)}
            acceptCharset="UTF-8"
          >
            <div className="formInputWrapper">
              <div className="contactFormFields">
                <label htmlFor="type">Type</label>
                <select id="type" name="type" form="contactForm" disabled={isSubmitted}>
                  <option value="Atelier">Atelier</option>
                  <option value="Boutique">Boutique</option>
                  <option value="Commande">Commande</option>
                  <option value="Cuisine-Locative" selected={urlSelectedParam === 'cuisinelocative' ? true : false}>Cuisine Locative</option>
                  <option value="Question">Informations</option>
                  <option value="Recette">Recette</option>
                  <option value="Traiteur" selected={urlSelectedParam === 'traiteur' ? true : false}>Traiteur </option>
                  <option value="Autre">Autre</option>
                </select>
                <label className="required" htmlFor="prenom">Prénom</label>
                <input id="prenom" type="text" name="prenom" disabled={isSubmitted} required />
                <label className="required" htmlFor="nom">Nom</label>
                <input id="nom" type="text" name="nom" disabled={isSubmitted} required />
                <label className="required" htmlFor="email">Courriel</label>
                <input type="email" name="email" id="email" disabled={isSubmitted} required />
                <label htmlFor="tel">
                  Téléphone <span>(Facultatif)</span>
                </label>
                <input type="tel" name="tel" id="tel" disabled={isSubmitted} />
              </div>
              <div className="contactFormMessage">
                <label className="required" htmlFor="message">Message</label>
                <textarea name="message" id="message" cols="30" rows="10" disabled={isSubmitted} required></textarea>
              </div>
              <input type="checkbox" name="miss" id="miss" />
            </div>
            <div className="contactButton">
              <button id="sendButton" type="submit" disabled={isSubmitted}>{isSubmitted ? "Message Envoyé" : "Envoyer"}</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
