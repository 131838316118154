import React, { useState, useEffect } from 'react';
import bullet from '../../assets/home/club/bullet.svg';

function HomeClub() {
    const [homeClubData, setHomeClubData] = useState({
        title: "",
        subtitle: "",
        description: "",
        items: [],
        button: {
            href: "",
            text: ""
        },
        image: {
            path: "",
            alt: ""
        }
    });

    useEffect(() => {
        const fetchHomeClubData = async () => {
            try {
                const response = await fetch('/content/home/home.json'); // Adjust the path accordingly
                const data = await response.json();

                setHomeClubData(data.club);
            } catch (error) {
                console.error('Error fetching Home Club data:', error);
            }
        };

        fetchHomeClubData();
    }, []);

    if (!homeClubData.title) {
        return <div className="loadingFiller"></div>;
    }

    return (
        <>
            <div id='homeClubWrapper'>
                <div className="homeFlexWrapper">
                    <div id="flex1">
                        <h3 className='h3Info'>{homeClubData.title}</h3>
                        <p className='pInfoSub'>{homeClubData.subtitle}</p>
                    </div>
                    <div id="flex2" className='hideOnDesktop'>
                        <img src={homeClubData.image.path} alt={homeClubData.image.alt}/>
                    </div>
                    <div id="flex3">
                        <p className='pInfo'>{homeClubData.description}</p>
                        
                            <ul className='ulInfoClub'>
                                    <li className='liInfoClub' >
                                        <span><img src={bullet} alt="" width={12} height={14}/></span>
                                        <span>{homeClubData.items[0]}</span>
                                    </li>
                                    <li className='liInfoClub' >
                                        <span><img src={bullet} alt="" width={12} height={14}/></span>
                                        <span>{homeClubData.items[1]}</span>
                                    </li>
                                    <li className='liInfoClub' >
                                        <span><img src={bullet} alt="" width={12} height={14}/></span>
                                        <span>{homeClubData.items[2]}</span>
                                    </li>
                            </ul>
                      
                        <a className='btnClub' href={homeClubData.button.href}>{homeClubData.button.text}</a>
                    </div>
                </div>
                <div id="flex2" className='hideOnMobile'>
                    <img src={homeClubData.image.path} alt={homeClubData.image.alt} loading='lazy' />
                </div>
            </div>
        </>
    );
}

export default HomeClub;
