import fb from '../assets/footerCL/Facebook.svg'
import mail from '../assets/footerCL/Mail.svg'

function FooterCL() {
    return (
        <>
            <div id='footerCLWrapper'>
                <div id='footerCLS1CL'>
                    <img src={mail} alt="Icone Enveloppe" />
                    <p><a href='/contact?cuisinelocative'>Contactez-nous</a></p>
                </div>
                <div className='footerSeparatorCL'></div>
                <div id='footerCLS2CL'>
                    <img src={fb} alt="Icone Facebook" />
                    <p>Suivez-nous sur <span><a href="https://www.facebook.com/CuisineLocativeSherbrooke">Facebook</a></span></p>
                </div>
                <div className='footerSeparatorCL'></div>
                <div id='footerCLS3CL'>

                    <div id='footerAdressCL'>
                        <p className='footerCLCorp'>Cuisine Locative</p>
                        <p className='footerStreet'>1103 rue du conseil</p>
                        <p className="footerCity">Sherbrooke, Québec</p>
                        <p className="footerZip">J1G 1M4</p>
                        <a href="tel:+18196685057">(819)668-5057</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FooterCL