import Header from './components/Header'
import AboutPresentation from './components/about/aboutPresentation'
import AboutContent from './components/about/aboutContent'
import FooterFF from './components/FooterFF'
import './styles/p_Home.css'


function About() {


  return (
    <>
      <Header/>
      <AboutPresentation/>
      <AboutContent/>
      <FooterFF/>
    </>
  )
}

export default About