import React, { useState, useEffect } from 'react';
import cornerTop from '../../assets/traiteur/cornerTop.svg'
import cornerBottom from '../../assets/traiteur/cornerBottom.svg'
import bullet from '../../assets/home/club/bullet.svg'

function TraiteurBoites() {
    const [traiteurBoitesData, setTraiteurBoitesData] = useState([]);

    useEffect(() => {
        const fetchTraiteurBoitesData = async () => {
            try {
                const response = await fetch('/content/traiteur/traiteur.json'); // Adjust the path accordingly
                const data = await response.json();

                const boites = Object.keys(data.boites).map((i) => ({
                    title: data.boites[i].title,
                    items: data.boites[i].items,
                }));

                setTraiteurBoitesData(boites);
            } catch (error) {
                console.error('Error fetching traiteur boites data:', error);
            }
        };

        fetchTraiteurBoitesData();
    }, []);

    if (!traiteurBoitesData) {
        return <div className="loadingFiller"></div>;
      }

    return (
        <>
            <div className="boitesWrapper">
                <div className="TitleWrapper">
                    <h4 className="Title">Nos services offerts</h4>
                    <div className='liner'></div>
                </div>
                <div className='boitesList'>
                    {traiteurBoitesData.map((boite, index) => {
                        const liElements = boite.items.map((item, j) => (
                            <li key={j}>
                                <span><img src={bullet} alt="List bullet" /></span>
                                {item}
                            </li>
                        ));

                        return (
                            <div key={index} className="boite">
                                <h5 className="boiteCategorie">{boite.title}</h5>
                                <ul className="boiteUl">{liElements}</ul>
                                <img className='cornerTop' src={cornerTop} alt="" />
                                <img className='cornerBottom' src={cornerBottom} alt="" />
                            </div>
                        );
                    })}
                </div>
                <div className='traiteurButtonsWrapper'>
                    <a href='https://ferventferment.square.site/traiteur-personnalise' className='btnViewMenu' target='_blank' rel="noreferrer">voir les menus</a>
                    <a href='/contact?traiteur' className='btnQuote'>DEMANDE DE SOUMISSION</a>
                </div>
            </div>
        </>
    );
}

export default TraiteurBoites;
