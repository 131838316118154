import React, { useState, useEffect } from 'react';
import traiteurTopper from '../../assets/atelier/fervent-ferment-ingredients-cuisine-atelierTopper.jpg';
import seperatorLogo from '../../assets/traiteur/separatorLogo.svg';

function AtelierPresentation() {
  const [atelierData, setAtelierData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/content/atelier/atelier.json');
        const data = await response.json();
        setAtelierData(data);
      } catch (error) {
        console.error('Error fetching atelier data:', error);
      }
    };

    fetchData();
  }, []);

  if (!atelierData) {
    return <div className="loadingFiller"></div>;
  }

  return (
    <>
      <div className="PresWrapper">
        <img className="PresTopper PresTopperTop" src={traiteurTopper} alt="" />
        <h2 className="PresTitle firstPageLoader">Ateliers</h2>
        <div className="Pres firstPageLoader">
          <p className="PresAtFF">Chez <span>FERVENT FERMENT</span>,</p>
          <p className="PresAtText">{atelierData.atelierPresentation.presentation}</p>
        </div>
        <div className="TitleWrapper">
          <img src={seperatorLogo} className='Logo' alt="" />
          <div className='liner'></div>
        </div>
        <div className='PresExplain'>
          <p>{atelierData.atelierPresentation.explain}</p>
        </div>
      </div>
    </>
  );
}

export default AtelierPresentation;
