
import Header from './components/Header'
import TraiteurPresentation from './components/traiteur/traiteurPresentation'
import TraiteurSlides from './components/traiteur/traiteurSlides'
import TraiteurBoites from './components/traiteur/traiteurBoites'
import FooterFF from './components/FooterFF'

function Traiteur() {
  return (
    <>
      <Header/>
      <TraiteurPresentation/>  
      <TraiteurBoites/>
      <TraiteurSlides/>
      <FooterFF/>
    </>
  )
}

export default Traiteur