import { BrowserRouter as Router, Route, Routes as ReactRoutes } from 'react-router-dom';
import Home from '../Home';
import Traiteur from '../Traiteur';
import Atelier from '../Atelier';
import CuisineLocative from '../CuisineLocative';
import About from '../About';
import Contact from '../Contact';
import NotFound from '../NotFound';

function Routes() {
  return (
    <Router>
      <ReactRoutes>
        <Route path="/" element={<Home />} />
        <Route path="/traiteur" element={<Traiteur/>} />
        <Route path="/atelier" element={<Atelier />} />
        <Route path="/a-propos" element={<About/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/cuisinelocative" element={<CuisineLocative/>} />
        <Route path="*" element={<NotFound/>} />
      </ReactRoutes>
    </Router>
  );
}

export default Routes;