import React, { useState, useEffect } from 'react';
import Slider from 'react-infinite-logo-slider';

function TraiteurSlides() {
    const [slidesTop, setSlidesTop] = useState([]);
    const [slidesBottom, setSlidesBottom] = useState([]);

    useEffect(() => {
        const fetchSlidesData = async () => {
            try {
                const response = await fetch('/content/traiteur/traiteur.json'); // Adjust the path accordingly
                const data = await response.json();

                const slidesTopData = Object.keys(data.slides.top).map((i) => (
                    <Slider.Slide className='TrSLidesClients' key={i}>
                        <img src={data.slides.top[i].path} alt={data.slides.top[i].alt} className='w-36' />
                    </Slider.Slide>
                ));

                const slidesBottomData = Object.keys(data.slides.bottom).map((i) => (
                    <Slider.Slide className='TrSLidesClients' key={i}>
                        <img src={data.slides.bottom[i].path} alt={data.slides.bottom[i].alt} className='w-36' />
                    </Slider.Slide>
                ));

                setSlidesTop(slidesTopData);
                setSlidesBottom(slidesBottomData);
            } catch (error) {
                console.error('Error fetching traiteur slides data:', error);
            }
        };

        fetchSlidesData();
    }, []);

    if (!slidesTop || !slidesBottom) {
        return <div className="loadingFiller"></div>;
      }

    return (
        <>
            <div className="TitleWrapper">
                <h4 className="Title">Évenements passés</h4>
                <div className='liner'></div>
            </div>
            <div className="TrClientsWrapper">
                <div className="TrClientsBG">
                    <Slider
                        width="250px"
                        duration={60}
                        pauseOnHover={true}
                        blurBorders={false}
                        blurBoderColor={'#fff'}
                    >
                        {slidesTop}
                    </Slider>
                    <Slider
                        width="250px"
                        duration={70}
                        toRight={false}
                        pauseOnHover={true}
                        blurBorders={false}
                        blurBoderColor={'#fff'}
                    >
                        {slidesBottom}
                    </Slider>
                </div>
            </div>
        </>
    );
}

export default TraiteurSlides;
