import React, { useState, useEffect } from 'react';
import './../../styles/c_CLSlidesClients.css';
import Slider from 'react-infinite-logo-slider'

function CLSlidesClients() {
    const [boitesElements, setBoitesElements] = useState([]);

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await fetch('/content/cuisineLocative/cuisineLocative.json'); // Adjust the path accordingly
                const data = await response.json();

                const clientLogos = Object.keys(data.clients).map((i) => (
                    <Slider.Slide className='CLSLidesClients' key={i}>
                        <a href={data.clients[i].url} target="_blank" rel="noreferrer">
                            <img src={data.clients[i].logoPath} alt={data.clients[i].alt} className='w-36' />
                        </a>
                    </Slider.Slide>
                ));

                setBoitesElements(clientLogos);
            } catch (error) {
                console.error('Error fetching client logos:', error);
            }
        };

        fetchClients();
    }, []);

    if (!boitesElements) {
        return <div className="loadingFiller"></div>;
      }

    return (
        <>
            <div className="CLClientsWrapper">
                <div className="CLClientsBG">
                    <Slider
                        width="175px"
                        duration={40}
                        pauseOnHover={true}
                        blurBorders={false}
                        blurBoderColor={'#fff'}
                    >
                        {boitesElements}
                    </Slider>
                </div>
                <div className="black"></div>
            </div>
        </>
    );
}

export default CLSlidesClients;
