import store from '../assets/footer/store.svg'
import calendar from '../assets/footer/calendar.svg'
import FF_logo_6 from '../assets/footer/FF_logo_6.svg'

function FooterFF() {
    return (
        <>
            <div id='footerFFWrapper'>
                <div id='footerFFS1'>
                    <img src={store} alt="" />
                    <p>Consulter l&apos;horaire de <span><a href="https://www.facebook.com/FerventFerment/" target='_blank' rel='noreferrer'>la boutique</a></span></p>
                </div>
                <div className='footerSeparator'></div>
                <div id='footerFFS2'>
                    <img src={calendar} alt="" />
                    <p>Voir les dates pour les <span><a href="https://ferventferment.square.site/atelier" target='_blank' rel='noreferrer'>prochains ateliers</a></span>!</p>
                </div>
                <div className='footerSeparator'></div>
                <div id='footerFFS3'>
                    <img src={FF_logo_6} alt="" />
                    <div id='footerAdress'>
                        <p className='footerFFCorp'>Fervent Ferment</p>
                        <p className='footerStreet'>1103 rue du conseil</p>
                        <p className="footerCity">Sherbrooke, Québec</p>
                        <p className="footerZip">J1G 1M4</p>
                        <a href="tel:+18196685057">(819)668-5057</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FooterFF