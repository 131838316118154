import { useState } from "react";
import FF_Header from '../assets/header/FF_Header.svg'
import FF_logo_2 from '../assets/header/FF_logo_2.svg'
import social_1 from '../assets/header/social_1.svg'
import social_2 from '../assets/header/social_2.svg'
import social_1_bk from '../assets/header/social_1_bk.svg'
import social_2_bk from '../assets/header/social_2_bk.svg'
import arrow_down from '../assets/header/arrow_down.svg'
import getActivePage from "../scripts/activePage";

const activePage = getActivePage();

function Header() {

    const [menuOpen, setMenuOpen] = useState(false);
    const [FFOpen, setFFOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
        if (document.body.style.overflow === 'hidden') {
            document.body.style.overflow = 'visible'
        } else {
            document.body.style.overflow = 'hidden'
        }
    };

    const toggleFF = () => {
        setFFOpen(!FFOpen);
    };

    return (
        <>
            <div id="headerWrapper" className={`${FFOpen ? 'open' : 'close'}`}>
                <a className="aSize" id="headerLeafLogo" href="/"><img src={FF_logo_2} alt="Feuille Fervent Ferment" /></a>
                <img className="headerCentralLogo" src={FF_Header} alt="Fervent Ferment Lactofermentation" />
                <div
                    id='menuButton'
                    className={`button ${menuOpen ? 'buttonOpen' : 'buttonClose'}`}
                    onClick={toggleMenu}
                >
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                </div>
                <div id='mobileNav' className={`${menuOpen ? 'open' : 'close'}`}>
                    <ul>
                        <li > <a className={`${activePage === '' ? 'active' : ''}`} href="/">Acceuil</a></li>
                        <div id="menuFF">
                            <li className={`fontDongle hoverFF ${activePage === 'traiteur' || activePage === 'atelier' ? 'headerFFGreen' : ''}`} onClick={toggleFF}>Fervent Ferment</li>
                            <img
                                id="arrow_down"
                                className={`mobileHidden ${menuOpen ? 'buttonOpen' : 'buttonClose'} ${FFOpen ? 'open' : 'close'}`}
                                onClick={toggleFF}
                                src={arrow_down}
                                alt="FerventFerment open arrow"
                            />
                            <div id="sublistWrapper" className={`${FFOpen ? 'open' : 'close'}`}>
                                <li className="sublist"><a href="https://ferventferment.square.site/shop/boutiqueferment/14">Boutique</a></li>
                                <li className="sublist"><a className={`${activePage === 'traiteur' ? 'active' : ''}`} href="/traiteur">Traiteur</a></li>
                                <li className="sublist"><a className={`${activePage === 'atelier' ? 'active' : ''}`} href="/atelier">Ateliers</a></li>
                                <li className="sublist"><a href="https://ferventferment.square.site/recettes">Recettes</a></li>
                            </div>
                        </div>
                        <li id='cuisineLocative' ><a className="fontCookie hoverCuisineLocative" href="/cuisinelocative">Cuisine <span className="fontCookie hoverCuisineLocative border-bottom">Locative</span></a></li>
                        <li><a className={`${activePage === 'a-propos' ? 'active' : ''}`} href="/a-propos">À propos</a></li>
                        <li><a className={`${activePage.includes('contact') ? 'active' : ''}`} href="/contact">Contact</a></li>

                    </ul>
                    <div id="social">
                        <a className="iconNotUnderline" href="https://www.facebook.com/FerventFerment" target='_blank' rel='noreferrer'><img id="headerSocial_1" src={social_1} alt="Facebook" /></a>
                        <a className="iconNotUnderline" href="https://www.instagram.com/ferventferment" target='_blank' rel='noreferrer'><img id="headerSocial_2" src={social_2} alt="Instagram" /></a>
                        <a className="iconNotUnderline" href="https://www.facebook.com/FerventFerment" target='_blank' rel='noreferrer'><img id="headerSocial_1_bk" src={social_1_bk} alt="Facebook" /></a>
                        <a className="iconNotUnderline" href="https://www.instagram.com/ferventferment" target='_blank' rel='noreferrer'><img id="headerSocial_2_bk" src={social_2_bk} alt="Instagram" /></a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header