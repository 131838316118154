import Header from './components/Header'
import AtelierPresentation from './components/atelier/atelierPresentation'
import AtelierBoites from './components/atelier/atelierBoites'
import FooterFF from './components/FooterFF'
import './styles/p_Home.css'


function Atelier() {


  return (
    <>
      <Header/>
      <AtelierPresentation/>  
      <AtelierBoites/>
      <FooterFF/>
    </>
  )
}

export default Atelier