import React, { useState, useEffect } from 'react';
import cornerTop from '../../assets/traiteur/cornerTop.svg';
import cornerBottom from '../../assets/traiteur/cornerBottom.svg';
import bullet from '../../assets/home/club/bullet.svg';

function AtelierBoites() {
  const [atelierData, setAtelierData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/content/atelier/atelier.json');
        const data = await response.json();
        setAtelierData(data);
      } catch (error) {
        console.error('Error fetching atelier data:', error);
      }
    };

    fetchData();
  }, []);

  if (!atelierData) {
    return <div className="loadingFiller"></div>;
  }

  const renderItems = (items) => {
    return items.map((item, j) => (
      <li key={j}>
        <span><img src={bullet} alt="" /></span>
        {item}
      </li>
    ));
  };

  const atelierBoites = Object.keys(atelierData.boites).map((i) => {
    const boite = atelierData.boites[i];
    const totalItems = boite.items.length;

    const items1 = boite.items.slice(0, Math.ceil(totalItems / 2));
    const items2 = boite.items.slice(Math.ceil(totalItems / 2), totalItems);

    return (
      <div className="boiteAtelier" key={i}>
        <h5 className="boiteCategorieAtelier">{boite.title}</h5>
        <img className={boite.image.class} src={boite.image.path} alt="" />
        <ul className="boiteUlAtelier">
          <div>{renderItems(items1)}</div>
          {items2.length > 0 && <div>{renderItems(items2)}</div>}
        </ul>
        <img className='cornerTopAtelier' src={cornerTop} alt="" />
        <img className='cornerBottomAtelier' src={cornerBottom} alt="" />
        <a href="https://ferventferment.square.site/shop/apprentissage/17" className="atelierLink">Nos ateliers</a>
      </div>
    );
  });

  return (
    <>
      <div className="boitesWrapper">
        <div className="TitleWrapper">
          <h4 className="Title">Nos ateliers</h4>
          <div className='liner'></div>
        </div>
        <div className='boitesListAtelier'>
          {atelierBoites}
        </div>
        <p className='atelierSocialInvite'>Ainsi, il y aura d&apos;autres ateliers distinctifs suivez-nous sur <a href="https://www.facebook.com/FerventFerment/">Facebook</a> pour rester informé!</p>
      </div>
    </>
  );
}

export default AtelierBoites;
