import React, { useState, useEffect } from 'react';
import aboutTopper from '../../assets/about/fervent-ferment-entrevue-publique-aboutTopper.jpg';
import seperatorLogo from '../../assets/traiteur/separatorLogo.svg';

function AboutPresentation() {
  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/content/about/about.json');
        const data = await response.json();
        setAboutData(data);
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };

    fetchData();
  }, []);

  if (!aboutData) {
    return <div className='loadingFiller'></div>;
  }

  return (
    <>
      <div className="PresWrapper">
        <img className="PresTopper PresTopperTop" src={aboutTopper} alt="" />
        <h2 className="PresTitle firstPageLoader">Qui sommes-nous ?</h2>
        <div className="Pres firstPageLoader">
          <p className="PresAtFF">Chez <span>FERVENT FERMENT</span>,</p>
          <p className="PresAtText">{aboutData.aboutPresentation.presentation}</p>
        </div>
        <div className="TitleWrapper">
          <img src={seperatorLogo} className='Logo' alt="" />
          <div className='liner'></div>
        </div>
      </div>
    </>
  );
}

export default AboutPresentation;
