import React, { useState, useEffect } from 'react';
import aboutFood from '../../assets/about/fervent-ferment-gauffre-cheong-aboutFood.png';
import aboutProfile from '../../assets/about/fervent-ferment-david-lavergne-aboutProfile.jpg';

function AboutContent() {
  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/content/about/about.json');
        const data = await response.json();
        setAboutData(data);
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };

    fetchData();
  }, []);

  if (!aboutData) {
    return <div></div>;
  }

  return (
    <>
      <div className="aboutWrapper">
        <div className="aboutTop">
          <div>
            <p className='aboutTopText'>{aboutData.aboutBody.paragraphe_1}</p>
            <p className='strucMarginTop'>{aboutData.aboutBody.paragraphe_2}</p>
            <div className="aboutCitation">
              <p>Rien ne se perds,</p>
              <p>Rien ne se crée,</p>
              <p>Tout se <span>transforme</span></p>
            </div>
          </div>
          <img src={aboutFood} alt="" className="aboutImg" />
        </div>
        <div className="aboutBottom">
          <p className='aboutBottomText aboutMobile'>{aboutData.aboutBody.paragraphe_3}</p>
          <p className='aboutBottomText aboutMobile strucMarginTop'>{aboutData.aboutBody.paragraphe_4}</p>
          <img src={aboutProfile} alt="" className="aboutImg" />
          <div>
            <p className='aboutBottomText aboutDesktop'>{aboutData.aboutBody.paragraphe_3}</p>
            <p className='aboutBottomText aboutDesktop '>{aboutData.aboutBody.paragraphe_4}</p>
            <p className='aboutBottomText'>{aboutData.aboutBody.bottomText}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutContent;
