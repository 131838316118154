import Header from './components/Header'
import HomeHero from './components/home/HomeHero'
import HomeSlide from './components/home/HomeSlides'
import HomeClub from './components/home/HomeClub'
import FooterFF from './components/FooterFF'


function Home() {


  return (
    <>
      <div className="firstPageLoader"><Header/></div>
      <HomeHero/>
      <HomeSlide/>
      <HomeClub/>
      <FooterFF/>
    </>
  )
}

export default Home