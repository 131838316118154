import contactFFLogo from '../../assets/contact/contactFFLogo.svg'
import contactCuisineLocative from '../../assets/contact/contactCuisineLocative.svg'

function ContactFindUs() {
    return (
        <>
            <div className="TitleWrapper">
                <h4 className="Title">Nous Trouver</h4>
                <div className='liner'></div>
            </div>
            <div className="contactFindWrapper">
                <div className='contactFindInfos'>
                    <div className='contactFindLogos'>
                        <img src={contactFFLogo} alt="" />
                        <img src={contactCuisineLocative} alt="" />
                    </div>
                    <div className="contactFindLiner"></div>
                    <div className='contactFindAdress'>
                        <p className='ContactStreet'>1103 rue du conseil</p>
                        <p className="ContactCity">Sherbrooke, Québec</p>
                        <p className="ContactZip">J1G 1M4</p>
                        <a href="tel:+18196685057">(819)668-5057</a>
                    </div>
                </div>
                <div className='contactFindMap'>
                    <iframe
                        title="Google Map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2801.2812069113033!2d-71.86656512452267!3d45.40366868815604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cb7b2f8e7e8d705%3A0x3ef1901b22267f36!2sFervent%20Ferment!5e0!3m2!1sfr!2sca!4v1700787472149!5m2!1sfr!2sca"
                        width="450"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    >
                    </iframe>
                </div>
            </div>
        </>
    )
}

export default ContactFindUs