import React, { useState, useEffect } from 'react';
import traiteurTopper from '../../assets/traiteur/fervent-ferment-table-traiteur-traiteurTopper.jpg'
import seperatorLogo from '../../assets/traiteur/separatorLogo.svg'

function TraiteurPresentation() {
    const [traiteurPresentationData, setTraiteurPresentationData] = useState({
        presentation: '',
        explain: ''
    });

    useEffect(() => {
        const fetchTraiteurPresentationData = async () => {
            try {
                const response = await fetch('/content/traiteur/traiteur.json'); // Adjust the path accordingly
                const data = await response.json();

                setTraiteurPresentationData({
                    presentation: data.traiteurPresentation.presentation,
                    explain: data.traiteurPresentation.explain,
                });
            } catch (error) {
                console.error('Error fetching traiteur presentation data:', error);
            }
        };

        fetchTraiteurPresentationData();
    }, []);

    if (!traiteurPresentationData) {
        return <div className="loadingFiller"></div>;
      }

    return (
        <>
            <div className="PresWrapper">
                <img className="PresTopper PresTopperBottom" src={traiteurTopper} alt="" />
                <h2 className="PresTitle firstPageLoader">Traiteur</h2>
                <div className="Pres firstPageLoader">
                    <p className="PresAtFF">Chez <span>FERVENT FERMENT</span>,</p>
                    <p className="PresAtText">{traiteurPresentationData.presentation}</p>
                </div>
                <div className="TitleWrapper">
                    <img src={seperatorLogo} className='Logo' alt="" />
                    <div className='liner'></div>
                </div>
                <div className='PresExplain'>
                    <p>{traiteurPresentationData.explain}</p>
                </div>
            </div>
        </>
    )
}

export default TraiteurPresentation;
