import React, { useState, useEffect } from 'react';
import contactTopper from '../../assets/contact/fervent-ferment-marche-publique-contactTopper.jpg';
import seperatorLogo from '../../assets/traiteur/separatorLogo.svg';

function ContactPresentation() {
  const [contactData, setContactData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/content/contact/contact.json');
        const data = await response.json();
        setContactData(data);
      } catch (error) {
        console.error('Error fetching contact data:', error);
      }
    };

    fetchData();
  }, []);

  if (!contactData) {
    return <div className="loadingFiller"></div>;
  }

  return (
    <>
      <div className="PresWrapper">
        <img className="PresTopper PresTopperTop" src={contactTopper} alt="" />
        <h2 className="PresTitle firstPageLoader">Contact</h2>
        <div className="Pres firstPageLoader">
          <p className="PresAtFF">Chez <span>FERVENT FERMENT</span>,</p>
          <p className="PresAtText">{contactData.contactPresentation}</p>
        </div>
        <div className="TitleWrapper">
          <div className='TitleWrapperContact'>
            <img src={seperatorLogo} className='Logo' alt="" />
            <p className='fontCookie'>Cuisine Locative</p>
          </div>
          <div className='liner'></div>
        </div>
      </div>
    </>
  );
}

export default ContactPresentation;
